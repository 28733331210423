import './App.css';
function Storia () {
    return (



                <div  className={"col-12 col-sm-8"}>
                   <h1 className={"lanostrastoria"}>La nostra storia</h1>
                    <div className={"card3"}>
                        <p1 className={"lanostrastoria2"}>Siamo una catena di fast food fondata nel 2120.
                            La  nostra idea di sostenibilità sfrutta la produzione di burger stampati in 3D contenenti solamente ingredienti vegetali.
                            Il risparmio sulla percentuale di acqua consumata in un anno sfiora circa il 24% rispetto ai fast food produttori di burger con derivati animali.
                            Promuove inoltre una riforestazione pari al 5% del terreno mondiale altrimenti devastato dalle continue costruzioni di allvamenti animali.
                            <br/><br/>Ci impegnamo con orgoglio a diffondere il nostro tipo di pensiero al fine di creare un mondo migliore e sostenibile.
                            <br/><br/>Questo nostro impegno lo abbiamo racchiuso in una combinazione perfetta di ingredienti. Componi anche tu il panino perfetto!
                        </p1>
                        <br/>
                    </div>
                    <br/><img className="ImmagineStoria"  src={"img.png"} alt={"..."}/>

            </div>

    )
}
export default Storia;