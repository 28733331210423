import './App.css';
import Slider from "./Slider";

 function HomePage () {
     return (


                 <div className={"azzurro"}>
                 <img className="ImmagineHeader"  src={"Primo_Div_1.png"} alt={"..."}/>
                         <img className="ImmagineHeader2"  src={"Secondo_div_2.png"} alt={"..."}/>
                             <div className={"componi"}>
                         <h1 className="scrittecomponi"  >SCOPRI LA NOSTRA STORIA E</h1>

                         <h2 className="scrittecomponi2">COMPONI IL TUO PANINO!</h2>
                                 <hr/>

                         </div>


                             <Slider></Slider>



            </div>
     )
 }
 export default HomePage