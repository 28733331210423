import './App.css';
import {Route, Routes, BrowserRouter, Outlet} from 'react-router-dom';
import Storia from "./Storia";
import HomePage from "./HomePage";
import Header from "./Header";
import CodicePromo  from "./CodicePromo"


function Main() {
    return (
        <div>
            <Header/>
            <Outlet />
        </div>
    );
}
function App() {
  return (

    <BrowserRouter>
        <Routes>
                <Route path="/" element={<Main />}>
                <Route path="" element={<HomePage />} />
                <Route path="info" element={<Storia />} />
                <Route path="promo" element={<CodicePromo />} />
            </Route>
        </Routes>
    </BrowserRouter>

  );
}
export default App;
