import {useState} from "react";
import CodicePromo from "./CodicePromo";
import "./App.css"

function Slider(){
const ImageSlider = ({slides}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const sliderStyles = {
        height: '90%',
        position: 'relative',

    }
    const slideStyles={
        margin: 'auto',
        width: '70%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${slides[currentIndex].url})`,
        marginBottom: '-8em',
        marginTop: -60,



    };

    const slideStyles2={
        ...slideStyles,
        marginTop: -300,
        backgroundImage: `url(${slides2[currentIndex2].url})`,
    };
    const slideStyles3={
        ...slideStyles,
        marginTop: -380,
        backgroundImage: `url(${slides3[currentIndex3].url})`,
        overrides:'slides'
    };
    const slideStyles4={
        ...slideStyles,
        marginTop: -310,
        backgroundImage: `url(${slides4[currentIndex4].url})`,
    };
    const slideStyles5={
        ...slideStyles,
        marginTop: -380,
        backgroundImage: `url(${slides5[currentIndex].url})`,
    };

    const leftArrowStyles = {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0,-50%)',
        left: '5px',
        fontSize: '45px',
        zIndex: 1,
        cursor: "pointer",

    }
    const rightArrowStyles = {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0,-50%)',
        right: '5px',
        fontSize: '45px',
        zIndex: 1,
        cursor: "pointer",
    }

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0
        const newIndex = isFirstSlide ? slides.length - 1: currentIndex - 1
        setCurrentIndex(newIndex);
    };
    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1
        const newIndex = isLastSlide ? 0: currentIndex + 1
        setCurrentIndex(newIndex);
    };

    const goToPrevious2 = () => {
        const isFirstSlide = currentIndex2 === 0
        const newIndex = isFirstSlide ? slides2.length - 1: currentIndex2 - 1
        setCurrentIndex2(newIndex);
    };
    const goToNext2 = () => {
        const isLastSlide = currentIndex2 === slides2.length - 1
        const newIndex = isLastSlide ? 0: currentIndex2 + 1
        setCurrentIndex2(newIndex);
    };
    const goToPrevious3 = () => {
        const isFirstSlide = currentIndex3 === 0
        const newIndex = isFirstSlide ? slides3.length - 1: currentIndex3 - 1
        setCurrentIndex3(newIndex);
    };
    const goToNext3 = () => {
        const isLastSlide = currentIndex3 === slides3.length - 1
        const newIndex = isLastSlide ? 0: currentIndex3 + 1
        setCurrentIndex3(newIndex);
    };
    const goToPrevious4 = () => {
        const isFirstSlide = currentIndex4 === 0
        const newIndex = isFirstSlide ? slides4.length - 1: currentIndex4 - 1
        setCurrentIndex4(newIndex);
    };
    const goToNext4 = () => {
        const isLastSlide = currentIndex4 === slides4.length - 1
        const newIndex = isLastSlide ? 0: currentIndex4 + 1
        setCurrentIndex4(newIndex);
    };
    const goToPrevious5 = () => {
        const isFirstSlide = currentIndex === 0
        const newIndex = isFirstSlide ? slides5.length - 1: currentIndex - 1
        setCurrentIndex(newIndex);
    };
    const goToNext5 = () => {
        const isLastSlide = currentIndex === slides5.length - 1
        const newIndex = isLastSlide ? 0: currentIndex + 1
        setCurrentIndex(newIndex);
    };

    return (


        <div style={sliderStyles}>
            <div style={leftArrowStyles} onClick={goToPrevious}><img src={"Freccia sx.png"} width={"70"} height={"70"}/></div>
            <div style={rightArrowStyles} onClick={goToNext}><img src={"Freccia_dx.png"}  width={"70"} height={"70"}/></div>
            <div style={slideStyles}></div>
            <div className={"panesopra"}>
            {
                currentIndex === 0 ? <h1>Giove</h1> : <div></div>
            }
            {
                currentIndex === 1 ? <h1>Mercurio</h1>:<div></div>

            }
            {
                currentIndex === 2 ? <h1>Nettuno</h1>:<div></div>

            }
            {
                currentIndex === 3 ? <h1>Marte</h1>:<div></div>

            }
            {
                currentIndex === 4 ? <h1>Urano</h1>:<div></div>

            }
            </div>
            <div style={sliderStyles}>
                <div style={leftArrowStyles} onClick={goToPrevious2}><img src={"Freccia sx.png"} width={"70"} height={"70"}/></div>
                <div style={rightArrowStyles} onClick={goToNext2}><img src={"Freccia_dx.png"}  width={"70"} height={"70"}/></div>
                <div style={slideStyles2}></div>
            </div>
            <div className={"verdura"}>
            {
                currentIndex2 === 0 ? <h1>Avocado</h1> : <div></div>
            }
            {
                currentIndex2 === 1 ? <h1>Insalata</h1>:<div></div>

            }
            {
                currentIndex2 === 2 ? <h1>Pomodori</h1>:<div></div>

            }
            {
                currentIndex2 === 3 ? <h1>Capricciosa</h1>:<div></div>

            }
            {
                currentIndex2 === 4 ? <h1>Zucchine</h1>:<div></div>

            }
            </div>
            <div style={sliderStyles}>
                <div style={leftArrowStyles} onClick={goToPrevious3}><img src={"Freccia sx.png"} width={"70"} height={"70"}/></div>
                <div style={rightArrowStyles} onClick={goToNext3}><img src={"Freccia_dx.png"}  width={"70"} height={"70"}/></div>
                <div style={slideStyles3}></div>
            </div>
            <div className={"cheese"}>
            {
                currentIndex3 === 0 ? <h1>Sottiletta</h1> : <div></div>
            }
            {
                currentIndex3 === 1 ? <h1>Cheddar</h1>:<div></div>

            }
            {
                currentIndex3 === 2 ? <h1>Salsa</h1>:<div></div>

            }
            {
                currentIndex3 === 3 ? <h1>Provola</h1>:<div></div>

            }
            {
                currentIndex3 === 4 ? <h1>Emmental</h1>:<div></div>

            }
            </div>

            <div style={sliderStyles}>
                <div style={leftArrowStyles} onClick={goToPrevious4}><img src={"Freccia sx.png"} width={"70"} height={"70"}/></div>
                <div style={rightArrowStyles} onClick={goToNext4}><img src={"Freccia_dx.png"}  width={"70"} height={"70"}/></div>
                <div style={slideStyles4}></div>
            </div>
            <div className={"burger"}>
            {
                currentIndex4 === 0 ? <h1>Dalia</h1> : <div></div>
            }
            {
                currentIndex4 === 1 ? <h1>Girasole</h1>:<div></div>

            }
            {
                currentIndex4 === 2 ? <h1>Violetta</h1>:<div></div>

            }
            {
                currentIndex4 === 3 ? <h1>Giglio</h1>:<div></div>

            }
            {
                currentIndex4 === 4 ? <h1>Tulipano</h1>:<div></div>

            }

            </div>
            <div style={sliderStyles}>
                <div style={leftArrowStyles} onClick={goToPrevious5}><img src={"Freccia sx.png"} width={"70"} height={"70"}/></div>
                <div style={rightArrowStyles} onClick={goToNext5}><img src={"Freccia_dx.png"}  width={"70"} height={"70"}/></div>
                <div style={slideStyles5}></div>
            </div>


            {   currentIndex === 2 && currentIndex2 === 1 && currentIndex3 === 0 && currentIndex4 === 2 ?
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                    COMPLIMENTI! HAI SBLOCCATO UN FILTRO!
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse"
                                 aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <img src={"Filtro_ no bottoni_lungo.png"} className={"filtro"} />
                                    <a href={"https://instagram.com/burgerlab_contest?igshid=MjEwN2IyYWYwYw=="}><img src={"Bottone_ig_trasparente.png"} className={"filtro2"} ></img></a>
                                    <a href={"https://www.instagram.com/ar/1705401743223113"}> <img src={"Prova filtro_trasparente.png"} className={"filtro3"}/></a>
                                </div>
                            </div>
                        </div>
                    </div> : <div></div>
            }
            {//currentIndex === 2 && currentIndex2 === 1 && currentIndex3 === 0 && currentIndex4 === 2 ? alert("Complimenti! Hai trovato la combinazione perfetta!"): <div></div> }
            }


            <CodicePromo></CodicePromo>
    </div>


    )
};



const slides = [
    {url:"01_Normale_Sopra.png", title: ""},
    {url: '02_Viola_Sopra.png'},
    {url: '05_Blue_Sopra.png'},
    {url: '04_Rosso_Sopra.png'},
    {url: '03_Verde_Sopra.png'},
]
const slides2 = [
    {url:'02_Avocado.png'},
    {url:'01_Insalata2.png'},
    {url:'02_Pomodori.png'},
    {url:'03_Insalata_Russa.png'},
    {url:'04_Zucchine.png'},
]
const slides3 = [
    {url:'01_sottiletta.png'},
    {url:'02_Cheddar.png'},
    {url:'03_Salsa_Cheese.png'},
    {url:'04_Provolone.png'},
    {url:'05_Emmental.png'},
]
const slides4 = [
    {url:'01_fake_burger.png'},
    {url:'02_Fake_Chicken_Burger.png'},
    {url:'05_Burger_Violaz.png'},
    {url:'04_Fake_Fish_Burger.png'},
    {url:'05_Burger_Burger.png'},
]
const slides5 = [
    {url:'01_Normale_Sotto.png'},
    {url:'02_Viola_Sotto.png'},
    {url:'05_Blue_Sotto.png'},
    {url:'04_Rosso_Sotto.png'},
    {url:'03_Verde_Sotto.png'},
]


const containerStyles2 = {
    width: '100%',
    height: '280px',
};
return (
    <div className={"HomePage"}>
        <div style = {containerStyles2} >
            <ImageSlider slides={slides} slides2={slides2}/>
            <slides></slides>
        </div>
    </div>
)
}
export default Slider;