import './App.css';
import {Link} from "react-router-dom";

function Header() {
    return (
        <nav className="navbar">
            <div className="container-fluid">
                <img src="DFd.png" alt="Logo" width="40" height="40"
                     className="d-inline-block align-text-top"/>
                <Link  to="" className="titolo">BurgerLab</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item1">
                            <Link to="/" className="navbar-brand">Home</Link>
                        </li>
                        <li className="nav-item2">
                            <Link  to="/info" className="navbar-brand">Chi siamo</Link>
                        </li>
                    </ul>

                </div>

            </div>
        </nav>
    ) }
 export default Header;


