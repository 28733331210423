import './App.css';
import {useState} from "react";

function CodicePromo() {
    function Verifica () {
        if (codice==="Sole" || codice==="sole")
        {
            return (
                setMostra(true)
            )

        }}
    const [mostra, setMostra] = useState(false)
    const [codice, setCodice] = useState('')
    return (


            <div className={"card"}>

                <h1 className="scrittaPromo">Inserisci Il Codice Promo</h1>

                <input data-input-address="" type="text"
                  name="address" value={codice} placeholder="Inserisci codice promo..."
                       onChange={(e)=>
                        setCodice(e.target.value)}
                  className="form-text pac-target-input" autoComplete="off"/>

                <button className="btn-icon" onClick={() => Verifica()}>Verifica</button>
                {
                    mostra &&
                    <div>

                            <a href="Menù Segreto.pdf" download="Menù Segreto.pdf"><button className={"download"}>Menu' segreto</button></a>

                    </div>
                }
            </div>

     )

 }
 export default CodicePromo